
<app-page-container [title]="data?'Create Sub Folder':'Create Folder'">
    <div body class="create-sprint-container animate fade-in" style="padding: 20px;">
        <div body class="create-sprint-form">
          <form [formGroup]="createFolder">
            <app-form-input [required]="true" [label]="'Folder Name'" [error]="createFolder.controls.folderName">
                <input type="text" formControlName="folderName" (change)="isDirty=true">
            </app-form-input>
            <!-- <app-form-select [required]="true" class="item" [label]="'Select channel'" [error]="createFolder.controls.channelId">
                <select formControlName="channelId" (change)="isDirty=true">
                    <option value="">Select</option>
                    <option *ngFor="let item of channelsList" [value]="item.id">
                    {{ item.name }}</option>
                </select>
            </app-form-select> -->
            <app-form-input [label]="'Select channel'" class="item" [error]="createFolder.controls.channelId" [required]="true" >
                <input type="text" placeholder="Select" formControlName="channelId"(change)="isDirty=true" [matAutocomplete]="autoChannel">
                <mat-autocomplete #autoChannel>
                    <mat-option *ngFor="let item of channelsList" [value]="item.name">{{item.name}}</mat-option>
                </mat-autocomplete>
            </app-form-input>
            <div style="margin-top: 5px;" *ngIf="checksuborguser===true">
              <app-form-input [label]="'SubOrganization Name'" >
                  <input type="text" placeholder="Search SubOrganization Name" formControlName="subOrgId" [matAutocomplete]="auto" 
                   (change)="isDirty=true" >
                  <div suffix style="padding-top:32px;"><img src="assets/icons/search.svg"></div>
                  <mat-autocomplete #auto="matAutocomplete">
                    <ng-container  *ngIf="checksuborguser===true">
                        <mat-option *ngFor="let item of suborganizationList" [value]="item.name">
                            {{ item.name }}
                        </mat-option>
                      </ng-container>
                  </mat-autocomplete>  
              </app-form-input>
              <!-- <mat-error *ngIf="serviceName.controls.folderName.hasError('forbiddenNamesFolderName') && folderList?.length>0"  style="font-size: 12px;">
                  please select valid folder
              </mat-error>  -->
          </div>
        </form>
        </div>
     
      <div class="btn-set">
        <app-button class="btn"  [size]="'sm'" (click)="closeDialog()">Cancel
        </app-button>
        <app-button class="btn" [size]="'sm'" (click)="create()" [ngClass]="{showloading1:btnDisabled==false,hideloading1:btnDisabled==true}">
            Save</app-button>
            <app-button *ngIf="btnDisabled==true" [outline]="true" class="btn" [size]="'sm'" [ngClass]="{showloading1:btnDisabled==true,hideloading1:btnDisabled==false}">
              <mat-progress-spinner style="margin-left: 20px;" [diameter]="30" class="mat-spinner-color" mode="indeterminate">
              </mat-progress-spinner>
  </app-button>
      </div>
    </div>
    
    </app-page-container>
    
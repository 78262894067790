<div class="user-home-container" id="main-view" #viewDOM>
    <div class="sidebar-warapper" (click)="closeDropdown()">
        <div class="sidebar">
            <div class="brand-name">
                <img class="large" src="/assets/brand-logo.png">
                <img class="small" src="/assets/brand-logo_mini.png">
            </div>
            <div class="nav-list" *ngIf="!isAdmin">
                <div class="item spacer"></div>
                <div class="item" *ngFor="let item of navList" [class.active]="item.route === activeSection">
                    <div class="item-main" 
                        [class.active]="item.route === activePage || (item.route === activeSection && !item.children)">
                        <img [routerLink]="'/home/'+item.route" [src]="'assets/icons/nav-bar/'+item.icon" class="def" *ngIf="item.name!='Help'">
                        <img [routerLink]="'/home/'+item.route" [src]="'assets/icons/nav-bar/'+item.iconSelected" class="focus">
                        <span [routerLink]="'/home/'+item.route"*ngIf="item.name!='Help'" class="label">{{ item.name }}</span>
                        <span *ngIf="item.name=='Help'" style="padding-left: 24px;" (click)="UserGuide()" class="label">{{ item.name }}</span>
                        <img class="def focus dis" style="overflow: hidden;" *ngIf="item.name=='IVR Tree (Beta)'" > <!--src="assets/icons/icons8-beta-26.png"-->
                        <img class="def focus dis" style="padding-left: 10px;overflow: hidden;" *ngIf="item.name!='Reports' && item.name!='Membership' && item.name!='Dashboard' && item.name!='Help'" src="assets/icons/icons8-external-link.svg" (click)="selectMenu(item.route)">
                    </div>
                    <div class="sub-menu bewel" *ngIf="item.children">
                        <ng-container *ngFor="let sub of item.children">
                            <div class="item-sub bewel" *ngIf="availableFeatures[sub.available] != false && sub.name=='Load Sprint Report' && memberShipPlan=='Advanced'"
                               
                                [class.active]="sub.route === activePage">
                                 <span class="label" [routerLink]="'/home/'+item.route+'/'+sub.route" >{{ sub.name }}</span>
                                 <img class="def1 focus" style="padding-left: 10px !important;display: initial !important" *ngIf="sub.name!='High Level Report' && sub.name!='Sprint Details Report' && sub.name!='Load Sprint Report' && sub.name!='Invoice' && sub.name!='Script Folder Report' && sub.name!='Sprint Folder Report'&& sub.name!='Audit logs'&& sub.name!='SubOrg Report'&& sub.name!='Admin SubOrg Report'" src="assets/icons/icons8-external-link.svg" (click)="selectSubMenu(item.route,sub.route)">
                            </div>
                            <div class="item-sub bewel"
                            [class.active]="sub.route === activePage" *ngIf="sub.name !='Load Sprint Report' && availableFeatures[sub.available] != false">
                                <span class="label" [routerLink]="'/home/'+item.route+'/'+sub.route">{{ sub.name }}</span>
                                <img class="def1 focus" style="padding-left: 7px !important;display: initial !important" *ngIf="sub.name!='High Level Report' && sub.name!='Sprint Details Report' && sub.name!='Load Sprint Report' && sub.name!='Invoice' && sub.name!='Script Folder Report' && sub.name!='Sprint Folder Report' && sub.name!='Audit logs' && sub.name!='SubOrg Report' && sub.name!='Admin SubOrg Report'" src="assets/icons/icons8-external-link.svg " (click)="selectSubMenu1(item.route,sub.route)">
                            </div>
                        </ng-container>
                        <div class="bewel"></div>
                    </div>
                    <ng-container *ngIf="!item.children">
                        <div class="bewel"></div>
                    </ng-container>
                </div>
                <div class="item spacer"></div>
            </div>
            <div class="nav-list" *ngIf="isAdmin">
                <div class="item spacer"></div>
                <div class="item" *ngFor="let item of adminnavList" [class.active]="item.route === activeSection">
                    <div class="item-main" 
                        [class.active]="item.route === activePage || (item.route === activeSection && !item.children)">
                        <img [routerLink]="'/home/'+item.route" [src]="'assets/icons/nav-bar/'+item.icon" class="def" >
                        <img [routerLink]="'/home/'+item.route" [src]="'assets/icons/nav-bar/'+item.iconSelected" class="focus">
                        <span [routerLink]="'/home/'+item.route" class="label">{{ item.name }}</span>

                        <img class="def focus dis" style="padding-left: 10px;overflow: hidden;" *ngIf="item.name!='Reports' && item.name!='Membership' && item.name!='Dashboard' && item.name!='Help'" src="assets/icons/icons8-external-link.svg" (click)="selectMenu(item.route)">
                    </div>
                    <div class="sub-menu bewel" *ngIf="item.children">
                        <ng-container *ngFor="let sub of item.children">
                            <div class="item-sub bewel" *ngIf="availableFeatures[sub.available] != false && sub.name=='Load Sprint Report' && memberShipPlan=='Advanced'"
                               
                                [class.active]="sub.route === activePage">
                                 <span class="label" [routerLink]="'/home/'+item.route+'/'+sub.route" >{{ sub.name }}</span>
                                 <img class="def1 focus" style="padding-left: 10px !important;display: initial !important" *ngIf="sub.name!='High Level Report' && sub.name!='Sprint Details Report' && sub.name!='Load Sprint Report' && sub.name!='Invoice' && sub.name!='Script Folder Report' && sub.name!='Sprint Folder Report'&& sub.name!='Audit logs'&& sub.name!='SubOrg Report'&& sub.name!='Admin SubOrg Report'" src="assets/icons/icons8-external-link.svg" (click)="selectSubMenu(item.route,sub.route)">
                            </div>
                            <div class="item-sub bewel"
                            [class.active]="sub.route === activePage" *ngIf="sub.name !='Load Sprint Report' && availableFeatures[sub.available] != false">
                                <span class="label" [routerLink]="'/home/'+item.route+'/'+sub.route">{{ sub.name }}</span>
                                <img class="def1 focus" style="padding-left: 7px !important;display: initial !important" *ngIf="sub.name!='High Level Report' && sub.name!='Sprint Details Report' && sub.name!='Load Sprint Report' && sub.name!='Invoice' && sub.name!='Script Folder Report' && sub.name!='Sprint Folder Report' && sub.name!='Audit logs' && sub.name!='SubOrg Report' && sub.name!='Admin SubOrg Report'" src="assets/icons/icons8-external-link.svg " (click)="selectSubMenu1(item.route,sub.route)">
                            </div>
                        </ng-container>
                        <div class="bewel"></div>
                    </div>
                    <ng-container *ngIf="!item.children">
                        <div class="bewel"></div>
                    </ng-container>
                </div>
                <div class="item spacer"></div>
            </div>
        </div>
    </div>
    <div class="body-container" *ngIf="user">
        <div class="view-wrapper">
            <div class="user-main-panel" id="menuHeaderpanel">
                <div class="menu-trigger" (click)="toggleSidePanel()"><img src="assets/icons/open-menu.svg"></div>
                <div class="user-actions">
                    <div style="margin-right: 30px; margin-top: 15px;">
                        <a class="create-btn" style="cursor: pointer;" *ngIf="(currentUrl=='home'||currentUrl=='dashboard')" (click)="UserGuide()"  >Help</a>
                    </div>
                    <div style="padding-top: 15px;cursor: pointer;" *ngIf="this.userQuery.getSamlUser()!='SamlUser' && (currentUrl=='home'||currentUrl=='dashboard'||currentUrl=='service'||currentUrl=='script'||currentUrl=='test-method')" (click)="openTestHistory()">Last Login Time: {{lastloginTime}}</div>
                    
                    <div class="notifications">
                        <img src="assets/icons/bell.svg" (click)="openNav()">
                        <div *ngIf="allRead" class="notification-dot"></div>
                    </div>
                    <div class="user-profile" [class.active]="showUserMenu">
                        <div class="dropdown-trigger" (click)="userMenuToggler()">
                            <div class="thumb">
                                <img  [src]="thumbnail" style="width: 40px;height: 40px;border-radius: 50%;">
                            </div>
                            <div class="label">{{user?.name}} {{user?.middle_name}}</div>
                        </div>
                        <div class="user-nav-list" *ngIf="showUserMenu"> 
                           <!-- <div (click)="navTo('/home/settings/profile/password')">Change Password</div>-->
                           <div *ngIf="!isAdmin" (click)="navTo('/home/settings')">Account Management</div>
                           <div *ngIf="!isAdmin" (click)="navTo('/home/settings/ChannelProfile/EmailProfile')">Configuration</div>
                           <div *ngIf="fullAccess==true && !isAdmin" (click)="navTo('/home/settings/userProfile')">User Management</div>
                            <div class="red" (click)="logout()">Logout</div>
                        </div>
                    </div>

                </div>
            </div>
            <div id="allMainPage" *ngIf="settingWidth" style="width: 80%;" (click)="closeDropdown()">
                 <router-outlet></router-outlet>
            </div>
            <div id="allMainPage" *ngIf="!settingWidth" style="width: 100%;" (click)="closeDropdown()">
                <router-outlet></router-outlet>
           </div>
            <div id="mySidenav" class="Notificationnav">
                <a href="javascript:void(0)" class="closebtn" (click)="closeNav()">&times;</a>
                <app-header></app-header>
              </div>
        </div>
        
    </div>
</div>